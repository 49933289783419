import "./styles.css";

import facebook from "../../assets/footer-social-icons/facebook.svg";
import instagram from "../../assets/footer-social-icons/instagram.svg";
import linkedIn from "../../assets/footer-social-icons/linkedin.svg";
import tiktok from "../../assets/footer-social-icons/tiktok.svg";

export default function Footer() {
  return (
    <>
      <section
        className="text-align-center p-60-0"
        role="contentinfo"
        aria-label="Footer"
      >
        <div className="margin-bottom-25 logo">
          <img
            src="/logo/logo-footer.svg"
            alt="A-List logo"
            width={99.72}
            height={40.64}
          />
        </div>
        <div className="footer-paragraph font-light">
          The Largest Platform For Brands & Creators To Collaborate.
        </div>
        <div className="footer-title font-demi-bold">Find us on</div>
        <div className="socials-container" aria-label="Social media links">
          <a
            href="https://www.instagram.com/alist.ae/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <img src={instagram} alt="Instagram logo" width={37} height={37} />
          </a>
          <a
            href="https://www.facebook.com/alistuae"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <img src={facebook} alt="Facebook logo" width={37} height={37} />
          </a>
          <a
            href="https://www.tiktok.com/@alist.ae"
            target="_blank"
            rel="noreferrer"
            aria-label="TikTok"
          >
            <img src={tiktok} alt="TikTok logo" width={37} height={37} />
          </a>
          <a
            href="https://www.linkedin.com/company/alistapp/"
            target="_blank"
            rel="noreferrer"
            aria-label="LinkedIn"
          >
            <img src={linkedIn} alt="LinkedIn logo" width={37} height={37} />
          </a>
        </div>
        <div className="footer-signature font-light">alist.ae | ©2025</div>
      </section>
    </>
  );
}
